/* noto-sans-kr-korean-100-normal*/
@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-display: swap;
    font-weight: 100;
    src: url('~@fontsource/noto-sans-kr/files/noto-sans-kr-korean-100-normal.woff2') format('woff2'), url('~@fontsource/noto-sans-kr/files/noto-sans-kr-korean-100-normal.woff') format('woff');
}
/* noto-sans-kr-korean-300-normal*/
@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: url('~@fontsource/noto-sans-kr/files/noto-sans-kr-korean-300-normal.woff2') format('woff2'), url('~@fontsource/noto-sans-kr/files/noto-sans-kr-korean-300-normal.woff') format('woff');
}
/* noto-sans-kr-korean-400-normal*/
@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url('~@fontsource/noto-sans-kr/files/noto-sans-kr-korean-400-normal.woff2') format('woff2'), url('~@fontsource/noto-sans-kr/files/noto-sans-kr-korean-400-normal.woff') format('woff');
}
/* noto-sans-kr-korean-500-normal*/
@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: url('~@fontsource/noto-sans-kr/files/noto-sans-kr-korean-500-normal.woff2') format('woff2'), url('~@fontsource/noto-sans-kr/files/noto-sans-kr-korean-500-normal.woff') format('woff');
}
/* noto-sans-kr-korean-700-normal*/
@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url('~@fontsource/noto-sans-kr/files/noto-sans-kr-korean-700-normal.woff2') format('woff2'), url('~@fontsource/noto-sans-kr/files/noto-sans-kr-korean-700-normal.woff') format('woff');
}
/* noto-sans-kr-korean-900-normal*/
@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-display: swap;
    font-weight: 900;
    src: url('~@fontsource/noto-sans-kr/files/noto-sans-kr-korean-900-normal.woff2') format('woff2'), url('~@fontsource/noto-sans-kr/files/noto-sans-kr-korean-900-normal.woff') format('woff');
}
/* roboto-latin-100-normal*/
@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-display: swap;
    font-weight: 100;
    unicode-range: U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A;
    src: url('~@fontsource/roboto/files/roboto-latin-100-normal.woff2') format('woff2'), url('~@fontsource/roboto/files/roboto-all-100-normal.woff') format('woff');
}
/* roboto-latin-300-normal*/
@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    unicode-range: U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A;
    src: url('~@fontsource/roboto/files/roboto-latin-300-normal.woff2') format('woff2'), url('~@fontsource/roboto/files/roboto-all-300-normal.woff') format('woff');
}
/* roboto-latin-400-normal*/
@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    unicode-range: U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A;
    src: url('~@fontsource/roboto/files/roboto-latin-400-normal.woff2') format('woff2'), url('~@fontsource/roboto/files/roboto-all-400-normal.woff') format('woff');
}
/* roboto-latin-500-normal*/
@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    unicode-range: U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A;
    src: url('~@fontsource/roboto/files/roboto-latin-500-normal.woff2') format('woff2'), url('~@fontsource/roboto/files/roboto-all-500-normal.woff') format('woff');
}
/* roboto-latin-700-normal*/
@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    unicode-range: U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A;
    src: url('~@fontsource/roboto/files/roboto-latin-700-normal.woff2') format('woff2'), url('~@fontsource/roboto/files/roboto-all-700-normal.woff') format('woff');
}
/* roboto-latin-900-normal*/
@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-display: swap;
    font-weight: 900;
    unicode-range: U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A;
    src: url('~@fontsource/roboto/files/roboto-latin-900-normal.woff2') format('woff2'), url('~@fontsource/roboto/files/roboto-all-900-normal.woff') format('woff');
}
